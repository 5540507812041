a {
  text-decoration: underline;
}

.r-0 {
  right: 0;
}

.t-0 {
  top: 0;
}

.cd {
  cursor: default;
}

.cp {
  cursor: pointer;
}

.change-border-style:hover {
  border-style: dashed;
}

button {
  width: fit-content;
}
