@import "~bootstrap/scss/bootstrap";


body {
  margin: 0;
  font-family: Courier New;
}

html, body, #root {
  height: 100%;
}